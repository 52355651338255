import React from "react";
import { Lang } from "../i18n";
import { Cabin } from "../types";
import CabinCard from "./CabinCard";

type CabinListProps = {
  cabins: Cabin[];
  lang: Lang;
  excludeUid?: string;
};

export default function CabinList(props: CabinListProps) {
  const { cabins, lang, excludeUid } = props;

  return (
    <div className="flex flex-row flex-wrap justify-between">
      {cabins
        .filter((cabin) => (excludeUid ? excludeUid !== cabin.uid : true))
        .map((cabin, index) => (
          <CabinCard key={`cabin-${index}`} cabin={cabin} lang={lang} />
        ))}
    </div>
  );
}
