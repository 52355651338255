import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql";
import { Link } from "gatsby";
import Image from "gatsby-image";
import React from "react";
import i18n, { Lang } from "../i18n";
import { Cabin } from "../types";

type CabinCardProps = {
  cabin: Cabin;
  lang: Lang;
};

export default function CabinCard(props: CabinCardProps) {
  const { cabin, lang } = props;

  const link = linkResolver({ type: "cabin", lang, uid: cabin.uid });

  return (
    <div className="w-full sm:w-56 mb-4 bg-white rounded-xl shadow-xl black-links">
      <Link to={link}>
        <h4 className="text-center">{cabin.name}</h4>

        <Image fluid={cabin.picture as any} />

        <div className="p-4">
          <ul className="flex flex-row place-content-between text-small pb-4">
            {cabin.price && (
              <li>
                <span className="font-bold">{cabin.price}</span>{" "}
                {i18n[lang].cabin.pricePerWeek}
              </li>
            )}
            {cabin.minPerson && cabin.maxPerson && (
              <li>
                <span className="font-bold">
                  {cabin.minPerson}–{cabin.maxPerson}
                </span>{" "}
                {i18n[lang].cabin.persons}
              </li>
            )}
            {cabin.area && (
              <li>
                <span className="font-bold">{cabin.area}</span> m²
              </li>
            )}
          </ul>

          <p>{cabin.shortDescription}</p>
          <p className="text-deepblue">{i18n[lang].cabin.readMore}</p>
        </div>
      </Link>
    </div>
  );
}
