import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import CabinList from "../components/CabinList";
import Layout from "../components/Layout";
import { Cabin } from "../types";
import htmlSerializer from "../utils/html-serializer";

export const query = graphql`
  query CabinsQuery($uid: String!, $lang: String!) {
    prismic {
      cabins(uid: $uid, lang: $lang) {
        _meta {
          lang
          uid
        }
        name
        description
      }
      allCabins(lang: $lang) {
        edges {
          node {
            _meta {
              id
              lang
              uid
            }
            name
            shortdescritpion
            startingpriceperweek
            squaremeters
            personsmin
            personsmax
            pictures {
              picture
              pictureSharp {
                id
                picture: childImageSharp {
                  main: fluid(
                    maxWidth: 400
                    maxHeight: 270
                    cropFocus: CENTER
                  ) {
                    src
                    aspectRatio
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Basic = (props: any) => {
  const data = props.data.prismic.cabins;

  if (!data) return null;

  const cabins: Cabin[] = props.data.prismic.allCabins.edges
    .map((edge: any) => edge.node)
    .map((node: any) => ({
      uid: node._meta.uid,
      name: node.name[0].text,
      shortDescription: node.shortdescritpion
        ? node.shortdescritpion[0].text
        : "",
      picture:
        node.pictures && node.pictures[0].pictureSharp
          ? node.pictures[0].pictureSharp.picture.main
          : null,
      price: node.startingpriceperweek,
      area: node.squaremeters,
      minPerson: node.personsmin,
      maxPerson: node.personsmax,
    }))
    .filter((cabin: Cabin) => cabin.picture);

  return (
    <Layout lang={data._meta.lang}>
      <div>
        <RichText render={data.name} />
        <RichText render={data.description} htmlSerializer={htmlSerializer} />
      </div>

      <div className="container mx-auto px-4 relative">
        <CabinList cabins={cabins} lang={props.pageContext.lang} />
      </div>
    </Layout>
  );
};

export default Basic;
