import { isArray, isString } from "lodash";
import React from "react";

const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

export default function (type, element, content, children, key) {
  switch (type) {
    case "paragraph":
      const removeNbsps = children.map((child: any) => {
        if (isArray(child)) {
          return child.map((item) => {
            if (isString(item)) {
              return item.replace(/\u00A0/g, " ");
            } else {
              return item;
            }
          });
        } else {
          return child;
        }
      });

      return React.createElement(
        "p",
        propsWithUniqueKey({ className: "my-4" }, key),
        removeNbsps
      );
    case "group-list-item":
      return React.createElement(
        "ul",
        propsWithUniqueKey(
          { className: "pl-4 my-4 list-disc list-inside" },
          key
        ),
        children
      );
  }

  return null;
}
